import React from "react"
import { Link } from "gatsby"

import Layoutoo from "../components/layoutoo"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layoutoo>
    <SEO title="Opening soon" />
    <h1 style={{textAlign: "center"}}>COMING SOON!</h1>
    <p style={{textAlign: "center"}}>We plan on launching early 2021 at our location on 15503 Babcock RD, San Antonio, TX 78255</p>
    
  </Layoutoo>
)

export default SecondPage
