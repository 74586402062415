import PropTypes from "prop-types"
import React from "react"
import logo from "../images/ailogo.png"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'


const Header2 = ({ siteTitle }) => (
  <header>    
    {/*style={{      background: `white`,      marginBottom: `1.45rem`,    }}  >    <h1 style={{ margin: 0 }}>        <Link          to="/"          style={{            color: `black`,            textDecoration: `none`,          }}        >          <img src={logo}/>          {/*{siteTitle}*//*}        </Link>      </h1>    <div      style={{        margin: `0 auto`,        maxWidth: 960,        padding: `1.45rem 1.0875rem`,      }}    >          </div>*/}
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
    <Navbar.Brand href="/">
      <img
        src={logo}
        width="160"
        height="30"
        style={{
          margin: 0,
          padding: 0
        }}
        alt="Aladdin International"
      />
      </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="/hours">Hours & Location</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link href="/">About us</Nav.Link>
      <Nav.Link eventKey={2} href="/contact">
        Contact us
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
  </header>
)

Header2.propTypes = {
  siteTitle: PropTypes.string,
}

Header2.defaultProps = {
  siteTitle: ``,
}

export default Header2
